// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Polished from "polished";
import * as Css_AtomicTypes from "bs-css/src/Css_AtomicTypes.bs.js";
import * as Utils$SimonKjellberg from "../Utils.bs.js";

function darken(amount, color) {
  return Utils$SimonKjellberg.color_of_string(Polished.darken(amount, Css_AtomicTypes.Color.toString(color)));
}

export {
  darken ,
  
}
/* polished Not a pure module */
