// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Screenfull from "screenfull";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.bs.js";
import * as Theme$SimonKjellberg from "../Theme.bs.js";
import * as Polished$SimonKjellberg from "../bindings/Polished.bs.js";

var windowFullscreen_0 = Css.height(Css.pct(100.0));

var windowFullscreen_1 = {
  hd: Css.margin("zero"),
  tl: /* [] */0
};

var windowFullscreen = {
  hd: windowFullscreen_0,
  tl: windowFullscreen_1
};

var $$window = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.flexDirection("column"),
          tl: {
            hd: Css.margin2(Css.em(1.0), "auto"),
            tl: {
              hd: Css.width(Css.pct(100.0)),
              tl: {
                hd: Css.borderRadius(Css.rem(0.625)),
                tl: {
                  hd: Css.backgroundColor(Theme$SimonKjellberg.Terminal.$$Window.backgroundColor),
                  tl: {
                    hd: Css.selector("&:fullscreen", windowFullscreen),
                    tl: {
                      hd: Css.selector("&:-ms-fullscreen", windowFullscreen),
                      tl: {
                        hd: Css.selector("&:-moz-full-screen", windowFullscreen),
                        tl: {
                          hd: Css.selector("&:-webkit-full-screen", windowFullscreen),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var windowTopbar = Curry._1(Css.style, {
      hd: Css.backgroundColor(Theme$SimonKjellberg.Terminal.Topbar.backgroundColor),
      tl: {
        hd: Css.height(Theme$SimonKjellberg.Terminal.Topbar.height),
        tl: {
          hd: Css.borderTopLeftRadius(Css.rem(0.5)),
          tl: {
            hd: Css.borderTopRightRadius(Css.rem(0.5)),
            tl: {
              hd: Css.padding2("zero", Css.rem(0.625)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var windowControl = Curry._1(Css.style, {
      hd: Css.display("inlineBlock"),
      tl: {
        hd: Css.width(Css.rem(0.75)),
        tl: {
          hd: Css.height(Css.rem(0.75)),
          tl: {
            hd: Css.padding("zero"),
            tl: {
              hd: Css.marginTop(Css.rem(0.625)),
              tl: {
                hd: Css.marginRight(Css.rem(0.25)),
                tl: {
                  hd: Css.borderRadius(Css.rem(0.5)),
                  tl: {
                    hd: Css.border(Css.px(1), "solid", "transparent"),
                    tl: {
                      hd: Css.focus({
                            hd: Css.outlineStyle("none"),
                            tl: /* [] */0
                          }),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var windowControlClose = Curry._1(Css.merge, {
      hd: windowControl,
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.backgroundColor(Theme$SimonKjellberg.Terminal.Controls.Close.backgroundColor),
              tl: {
                hd: Css.focus({
                      hd: Css.borderColor(Polished$SimonKjellberg.darken(0.15, Theme$SimonKjellberg.Terminal.Controls.Close.backgroundColor)),
                      tl: /* [] */0
                    }),
                tl: /* [] */0
              }
            }),
        tl: /* [] */0
      }
    });

var windowControlMinimize = Curry._1(Css.merge, {
      hd: windowControl,
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.backgroundColor(Theme$SimonKjellberg.Terminal.Controls.Minimize.backgroundColor),
              tl: {
                hd: Css.focus({
                      hd: Css.borderColor(Polished$SimonKjellberg.darken(0.15, Theme$SimonKjellberg.Terminal.Controls.Minimize.backgroundColor)),
                      tl: /* [] */0
                    }),
                tl: /* [] */0
              }
            }),
        tl: /* [] */0
      }
    });

var windowControlMaximize = Curry._1(Css.merge, {
      hd: windowControl,
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.backgroundColor(Theme$SimonKjellberg.Terminal.Controls.Maximize.backgroundColor),
              tl: {
                hd: Css.focus({
                      hd: Css.borderColor(Polished$SimonKjellberg.darken(0.15, Theme$SimonKjellberg.Terminal.Controls.Maximize.backgroundColor)),
                      tl: /* [] */0
                    }),
                tl: /* [] */0
              }
            }),
        tl: /* [] */0
      }
    });

var windowContentFullscreen_0 = Css.maxHeight(Css_Legacy_Core.Calc.$neg(Css.vh(100), Theme$SimonKjellberg.Terminal.Topbar.height));

var windowContentFullscreen = {
  hd: windowContentFullscreen_0,
  tl: /* [] */0
};

var windowContent = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.position("relative"),
          tl: {
            hd: Css.color(Theme$SimonKjellberg.Terminal.Content.color),
            tl: {
              hd: Css.maxHeight(Theme$SimonKjellberg.Terminal.Content.maxHeight),
              tl: {
                hd: Css.margin2("zero", Css.rem(0.625)),
                tl: {
                  hd: Css.selector("." + ($$window + ":fullscreen &"), windowContentFullscreen),
                  tl: {
                    hd: Css.selector("." + ($$window + ":-ms-fullscreen &"), windowContentFullscreen),
                    tl: {
                      hd: Css.selector("." + ($$window + ":-moz-full-screen &"), windowContentFullscreen),
                      tl: {
                        hd: Css.selector("." + ($$window + ":-webkit-full-screen &"), windowContentFullscreen),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var Styles = {
  windowFullscreen: windowFullscreen,
  $$window: $$window,
  windowTopbar: windowTopbar,
  windowControl: windowControl,
  windowControlClose: windowControlClose,
  windowControlMinimize: windowControlMinimize,
  windowControlMaximize: windowControlMaximize,
  windowContentFullscreen: windowContentFullscreen,
  windowContent: windowContent
};

function Terminal(Props) {
  var children = Props.children;
  var windowRef = React.useRef(null);
  var handleClickMaximize = React.useCallback((function (param) {
          if (!Screenfull.isEnabled) {
            return ;
          }
          var r = windowRef.current;
          if (!(r == null)) {
            Screenfull.toggle(r);
            return ;
          }
          
        }), [windowRef]);
  return React.createElement("div", {
              ref: windowRef,
              className: $$window
            }, React.createElement("div", {
                  className: windowTopbar
                }, React.createElement("button", {
                      className: windowControlClose
                    }), React.createElement("button", {
                      className: windowControlMinimize
                    }), React.createElement("button", {
                      className: windowControlMaximize,
                      onClick: handleClickMaximize
                    })), React.createElement("div", {
                  className: windowContent
                }, children));
}

var make = Terminal;

var $$default = Terminal;

export {
  Styles ,
  make ,
  $$default ,
  $$default as default,
  
}
/* windowFullscreen Not a pure module */
