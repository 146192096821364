
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ChatMessage"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ChatMessage"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"ts"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"thread_ts"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"text"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"user"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"color"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"replies"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ChatMessageReply"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"edited"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":191}};
    doc.loc.source = {"body":"#import './ChatMessageReplyFragment.graphql'\n\nfragment ChatMessage on ChatMessage {\n  ts\n  thread_ts\n  text\n  user {\n    name\n    color\n  }\n  replies {\n    ...ChatMessageReply\n  }\n  edited\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require('./ChatMessageReplyFragment.graphql').definitions));


      module.exports = doc;
    
